import axios from 'axios';

export default {
  find: function (id) {
    return axios.get(`/api/exportPotential/find?id=${id}`);
  },
  update: function (request) {
    return axios.put(`/api/exportPotential/update?id=${request.id}`, request);
  },
};
