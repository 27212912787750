<template>
  <div class="container-page" v-if="item">
    <p class="title-inner">Карточка экспортного потенциала товара</p>

    <div class="row">
      <div class="form-group required disabled-select">
        <ComponentInput
          label="Коды ТН ВЭД"
          :select2Settings="select2SettingsMultiple"
          :select2Options="tnved"
          v-model="item.tnvedIds"
          required
          multiple
          type="select2"
        />
        <i
          class="fas fa-search catalog-icon mr-2"
          title="Найти в каталоге"
          @click="openCatalog('nsitnved', 'Выбор товара')"
        ></i>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6">
        <ComponentInput label="Потенциал, млн $ США" v-model="item.value" type="number" />
      </div>
      <div class="form-group col-6">
        <ComponentInput label="Фактический потенциал, $ США" v-model="item.exportValue" type="number" />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6">
        <ComponentInput label="Фактический экспорт, млн $ США" v-model="item.exportFact" type="number" />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6">
        <ComponentInput
          label="Страна-экспортер"
          :select2Options="countries"
          v-model="item.fromCountryId"
          :select2Settings="select2SettingsNoClear"
          type="select2"
        />
      </div>
      <div class="form-group col-6">
        <ComponentInput
          label="Страна-импортер"
          :select2Options="countries"
          v-model="item.toCountryId"
          :select2Settings="select2SettingsNoClear"
          type="select2"
        />
      </div>
    </div>
    <div class="checkboxInput">
      <input type="checkbox" v-model="item.isActive" id="chkActive" />
      <label for="chkActive" class="form-label">Запись активна</label>
    </div>

    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
  <section>
    <ModalComponent v-model="catalog.open" :width="950" :title="catalog.title" @close="closeCatalog">
      <CatalogSelector
        v-bind:selected-ids="catalog.selectedIds"
        :route="catalog.route"
        :multiple="true"
        @select="updateCatalogField"
        @close="closeCatalog"
      />
    </ModalComponent>
  </section>
</template>

<script>
  import Constants from '@/common/constants';

  import CountryApi from '@/modules/nsi/api/country';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import CatalogApi from '../../nsi/api/catalog';
  import CatalogSelector from '../../nsi/components/CatalogSelector';
  import Api from '../api/exportPotential.js';

  export default {
    name: 'ExportPotentialCard',
    components: { ComponentInput, ButtonStock, CatalogSelector, ModalComponent },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        countries: [],
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
        select2SettingsNoClear: Constants.select2SettingsNoClear,
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        tnved: [],
        catalog: {
          route: 'nsitnved',
          title: 'Выбор товара',
          selectedIds: [],
          open: false,
        },
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        Api.find(this.id).then((response) => {
          this.item = response.data;
        });
      },
      loadDictionaries() {
        CountryApi.search(this.defaultRequest).then((response) => {
          this.countries = response.data.items.map((x) => {
            return { text: x.name, id: x.id };
          });
        });
      },
      save() {
        Api.update(this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.$router.push({ name: 'ExportPotential' });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'ExportPotential' });
      },
      updateCatalogField(selection) {
        this.closeCatalog();
        this.item.tnvedIds = selection;
      },
      closeCatalog() {
        this.catalog.open = false;
      },
      openCatalog() {
        const selectedIds = this.item.tnvedIds;
        this.catalog.selectedIds = selectedIds;
        this.catalog.open = true;
      },
    },
    watch: {
      'item.tnvedIds': {
        handler: function (value) {
          if (!value) return;
          CatalogApi.getListByIds('nsitnved', value).then((response) => {
            const list = response.data.map((x) => ({ id: x.id, text: x.code }));
            list.forEach((item) => {
              if (!this.tnved.find((x) => x.id === item.id)) {
                this.tnved.push(item);
              }
            });
          });
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
